import React, { useState } from 'react'
import RadioSelect from '../components/html/RadioSelect'
import TextInput from '../components/html/TextInput'
import Datepicker from "react-tailwindcss-datepicker"; 
import { dateWithSlashFormatter, formatDateToDDMMYYYY, formatFrenchPhoneNumber, ibanFormatter, postcodeFormatter, toPrice } from '../utils/formatters'
import { Navigate, useNavigate } from 'react-router-dom';
import { testDateDDMMAAAA, testEmail, testIBAN, testPhone, testPostCode } from '../utils/verifier';
import { toast } from 'react-toastify';

const GenerateDevis = () => {

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const [token, setToken] = useState('');

  const [formData, setFormData] = useState({
    courtier: 'CO0075901991',
    identifiantWs: 'lassurances',
    produitChoisi: 'MRH',
    produitType: 'MRH',
    indemnMobilier: "VALEUR_USAGE",
    tcv: 20,
    typeHabitation: '',
    typeResidence: '',
    qualiteAssure: '',
    codePostal: '',
    ville: '',
    habitationDejaAssure: 'NON',
    nbrPiecePrincipale: '',
    nbrPiecePrincipalePlus30m: '',
    nbPiecesPrincipalesSup50: '',
    nbrDependance: '',
    nbrDependancePlus30m: '',
    resilieAutreAssureur: '',
    sinistres2ansDerniers: '',
    insertOuCheminee: 'NON',
    piscine: 'NON',
    presencePicineOuTennis: 'NON',
    nbEnfantMineur: '',
    nbrEtageImmb: 5,
    nbrEtage: 5,
    etageBien: '',
    presenceVeranda: 'NON',
    capitalMobilier: '8000',
    souscripteurAdressePostale: '',
    souscripteurCV: 'MR',
    souscripteurVille: '',
    souscripteurCodePostal: '',
    souscripteurNom: '',
    souscripteurPrenom: '',
    souscripteurEmail: '',
    souscripteurTel: '',
    souscripteuribanPrelevemnt: '',
    souscripteurRevenuMensuel: '',
    souscripteursituationFam: 'CELIBATAIRE',
    souscripteurprofession: '',
    modePaiement: 'CB',
    moyenProtectionVols: 'NON',
  });

  const [errors, setErrors] = useState({});

  const [formules, setFormules] = useState();
  const [formulesSelected, setFormulesSelected] = useState();
  const handleSelectFormule = (formule) => {
    setFormulesSelected(formule);
  }

  const [ville, setVille] = useState('');
  const [villesFromPostalCode, setVillesFromPostalCode] = useState('');

  const getCityFromPostalCode = async (postalCode) => {
    if(postalCode.length > 4) {
      try {
        setVillesFromPostalCode([]);
        const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${postalCode}`)
        const data = await response.json();
        const citys = data.features[0].properties.city +', '+  data.features[0].properties.postcode;
        setVillesFromPostalCode(citys);
      } catch (error) {
        console.error(error)
      }
    }
  }

  const onVilleSelected = (ville) => {
    setVille(ville);
    const infoVille = ville.split(', ')
    setFormData({...formData, 'codePostal': infoVille[1], 'ville': infoVille[0]});
    // setFormData({...formData, 'ville': infoVille[0]});
    setVillesFromPostalCode('');
  }

  const [dates, setDates] = useState({});
  const OnChangeDates = (dates) => {
    setDates(dates);
    setFormDataValue('dateEffet', formatDateToDDMMYYYY(dates.startDate));
  }
  const OnChangeDatesNaissance = (dates) => {
    setDates(dates);
    setFormDataValue('dateNaissance', formatDateToDDMMYYYY(dates.startDate));
  }

  const setFormDataValue = (key, value) => {
    setFormData({...formData, [key]: value});
  }

  const getFormDataValue = (key) => {
    return formData[key];
  }

  const addStep = () => {
    window.scrollTo(0, 0);
    setStep(step + 1);
  }

  const nextStep = () => {
    if(step == 1 && testDateDDMMAAAA(getFormDataValue('dateEffet')) &&  getFormDataValue('typeHabitation') != "") {

      addStep();
    }

    if(step == 2 && getFormDataValue('qualiteAssure') != '' && testPostCode(getFormDataValue('codePostal')) && getFormDataValue('ville') != '' && getFormDataValue('nbrPiecePrincipale') != '' && getFormDataValue('typeResidence') != '' ) {
      if(getFormDataValue('nbrPiecePrincipale') == 1) {
        setFormDataValue('produitType', 'MRH');
      }
      if(getFormDataValue('nbrPiecePrincipale') > 1) {
        setFormDataValue('produitType', 'MRH_GENERALI');
      }
      addStep();
    }

    if(step == 3 && getFormDataValue('capitalMobilier') != '') {
      addStep();
    }
    if(step == 5 && formulesSelected != undefined) {
      addStep();
    }
    
  }

  const prevStep = () => {
    setStep(step - 1);
  };

  const getDevis = async () => {
    if(step == 4 && getFormDataValue('resilieAutreAssureur') == 'OUI' || getFormDataValue('sinistres2ansDerniers') == 'OUI' || getFormDataValue('habitationUsageProfessionel') == 'OUI') {
      setStep(6);
      return;
    }
    setLoading(true);

     const login = await fetch(`${process.env.REACT_APP_SERVER_URI}/server/api-login.php`, {method: 'POST'});
        // Recupere le JSON de la reponse
        const loginJson = await login.json();

        console.log(loginJson);
        setToken(loginJson.token);
      // URL encode les donnees du formulaire
      const formDataUrlEncoded = new URLSearchParams();
      for (const key in formData) {
        if (formData[key] != '') {
          formDataUrlEncoded.append(key, formData[key]);
        }
      }


      try {
        const formBody = Object.keys(formData).map(key => encodeURIComponent(key) + '='+ encodeURIComponent(formData[key] == ''? 0 : formData[key])).join('&');
        const dataForm = new FormData();
        dataForm.append('formData', formBody);
        dataForm.append('token', loginJson.token);
        console.log(formBody);
        const formules = await fetch(`${process.env.REACT_APP_SERVER_URI}/server/api-tarificateur.php`, {
          method: 'POST',
          body: dataForm
        });

        const formulesJson = await formules.json();
        setFormules(formulesJson);
        window.scrollTo(0, 0);
          setStep(5);
        console.log(formulesJson);
        setLoading(false);
      } catch (error) {
        
      }
  }

  const getContrat = async (type = null) => {
    if(step == 6 && getFormDataValue('resilieAutreAssureur') == 'OUI' || getFormDataValue('sinistres2ansDerniers') == 'OUI' || getFormDataValue('habitationUsageProfessionel') == 'OUI') {
      const sendDataNotEligible = new FormData();
      for (const key in formData) {
        sendDataNotEligible.append(key, formData[key]);
      }

      const notEligible = fetch(`${process.env.REACT_APP_SERVER_URI}/server/api-send-not-eligible.php`, {
        method: 'POST',
        body: sendDataNotEligible
      }).then(response => navigate('/success'));

      return;
    }
    if(getFormDataValue('souscripteurAdressePostale') != '' && getFormDataValue('souscripteurVille') != '' && testPostCode(getFormDataValue('souscripteurCodePostal')) && getFormDataValue('souscripteurNom') != '' && getFormDataValue('souscripteurPrenom') != '' && testEmail(getFormDataValue('souscripteurEmail')) && testPhone(getFormDataValue('souscripteurTel')) && testDateDDMMAAAA(getFormDataValue('dateNaissance')) /* && testIBAN(getFormDataValue('souscripteuribanPrelevemnt')) */ ) {

    // Transforme les donnees du formulaire en FormData
    const dataForm = new FormData();
    for (const key in formData) {
      dataForm.append(key, formData[key]);
      console.log(token);
      dataForm.append('token', token);
    //   if(formulesSelected.formule == 'CONFORT') {
    //     dataForm.append('formuleChoisi', 'CONFORT');
    //   }
    //   if(formulesSelected.formule == 'OPTIMALE') {
    //     dataForm.append('formuleChoisi', 'OPTIMUM');
    //   }
    //   if(formulesSelected.formule == 'PREMIUM') {
    //     dataForm.append('formuleChoisi', 'COMPLETE');
    //   }
    // TO
      dataForm.append('formuleChoisi', formulesSelected.formule);
      if(type == 'subscription') {
        dataForm.append('flagType', type);
      }
    }

    setLoading(true);

    const createContact = await fetch(`${process.env.REACT_APP_SERVER_URI}/server/api-save-contrat.php`, 
    {
      method: 'POST',
      body: dataForm
    });


    const createContactJson = await createContact.json();
    console.log(createContactJson);
    setPdf(createContactJson.document);
    setStep(7);
    setLoading(false);
    if(createContactJson.signature) {
      window.open(createContactJson.signature);
    }
  }

  }

  const [pdf, setPdf] = useState('');





  return (
    <div className='bg-[#DAF2FF] h-screen'>
      <div className='text-center py-12 text-orange-500 text-3xl font-semibold bg-white'>
        Votre devis en ligne
      </div>
      <div className='bg-[#DAF2FF]'>
        <div className={`container mx-auto ${step == 5 ? 'w-full mobile:w-full': 'md:w-2/3 mobile:w-full'} mobile:px-3`}>

          {
            step === 1 &&
            <>
            <h2 className='text-2xl mobile:text-lg font-semibold text-center py-12'>Bonjour, bienvenue sur votre devis</h2>
            <p className='text-center text-lg py-4'>Afin de vous proposer les meilleurs tarifs nous avons besoin de quelques informations</p>
            
            <div className='flex justify-center'>
              <div className="h-3 bg-white w-1/2 rounded-xl relative">
                <div className="h-3 w-1/5 bg-orange-500 rounded-xl absolute"></div>
              </div>
            </div>

            <h3 className='text-md font-semibold py-6'>Que souhaitez vous assurer ?</h3>
            <div className='grid grid-cols-2 mobile:grid-cols-2 gap-3 items-center justify-center'>
              <RadioSelect value={getFormDataValue('typeHabitation')} onclick={() => setFormDataValue('typeHabitation', 'MAISON_INDIVIDUELLE')} image={window.location.origin + '/images/MaisonVec.png'} name='MAISON_INDIVIDUELLE'label='Une maison'/>
              <RadioSelect value={getFormDataValue('typeHabitation')} onclick={() => setFormDataValue('typeHabitation', 'APPARTEMENT')} image={window.location.origin + '/images/Immeuble.png'} name='APPARTEMENT'label='Un appartement'/>
            </div>

            <div className="">
                <h3 className='text-md font-semibold py-6'>A quel date assurer votre logement ?</h3>
                <Datepicker
                  startFrom={new Date()}
                  i18n={"fr"}
                  placeholder="Date d'effet"
                  displayFormat={"DD/MM/YYYY"}
                  startWeekOn="mon"
                  minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                  inputClassName={'py-3 w-full px-6 rounded-xl focus:outline-orange-500'}
                  useRange={false} 
                  asSingle={true} 
                  value={dates} 
                  // Convert date to dd/mm/yyyy
                  onChange={(value) => OnChangeDates(value)} 
                  /> 
                </div>

            <h3 className='text-md font-semibold py-6'>Êtes-vous actuellement assuré(e) ?</h3>
            <div className="grid grid-cols-2 gap-3">
            <RadioSelect onclick={() => setFormDataValue('habitationDejaAssure', 'OUI')} name={'OUI'} value={getFormDataValue('habitationDejaAssure')} label='OUI' />
            <RadioSelect onclick={() => setFormDataValue('habitationDejaAssure', 'NON')} name={'NON'} value={getFormDataValue('habitationDejaAssure')} label='NON' />
            </div>

            {/* habitationDejaAssure */}

            <button onClick={() => nextStep()} className='my-4 py-4 w-full bg-orange-500 rounded-md text-white font-semibold'>Obtenir mon devis</button>
            </>
          }
          
          {step === 2 &&
            <div>
             
             <h2 className='text-2xl mobile:text-lg font-semibold text-center py-12'>Les détail de votre logement</h2>
             <p className='text-center text-lg py-4'>Afin de vous proposer les meilleurs tarifs nous avons besoin de quelques informations sur le logement que vous souhaitez assurer</p>
              
             <div className='flex justify-center'>
              <div className="h-3 bg-white w-1/2 rounded-xl relative">
                <div className="h-3 w-2/5 bg-orange-500 rounded-xl absolute"></div>
              </div>
            </div>

              <h3 className='text-md font-semibold py-6'>Dans le logement vous êtes ?</h3>
              <div className='grid grid-cols-2 mobile:grid-cols-1 gap-3 items-center justify-center'>
                <RadioSelect onclick={() => setFormDataValue('qualiteAssure', 'LOCATAIRE_OCCUPANT')} name={'LOCATAIRE_OCCUPANT'} value={getFormDataValue('qualiteAssure')} label='Locataire'/>
                <RadioSelect onclick={() => setFormDataValue('qualiteAssure','PROPRIETAIRE_OCCUPANT')} name={'PROPRIETAIRE_OCCUPANT'} value={getFormDataValue('qualiteAssure')} label='Propriétaire'/>
              </div>
              <div className='grid grid-cols-1 gap-4 mobile:grid-cols-1 mobile:gap-2'>
                <div className="relative">
                  <h3 className='text-md font-semibold py-6'>Le logement est situé ...</h3>
                  <TextInput value={ville} onValueChange={(e)  => { getCityFromPostalCode(e); setVille(e)}} placeholder='Code postal ou ville'/>
                  {villesFromPostalCode &&
                  <div className='bg-white p-4 rounded-lg shadow-xl border-2 border-orange-500 absolute w-full'>
                    {(
                      <p onClick={() => onVilleSelected(villesFromPostalCode)}>{villesFromPostalCode}</p>
                    )}
                  </div>
                  }

                </div>
                
              </div>
              <div>
                <h3 className='text-md font-semibold py-6'>Nombre de pièces principales</h3>
                <TextInput value={getFormDataValue('nbrPiecePrincipale')} onValueChange={(e) => setFormDataValue('nbrPiecePrincipale', e)} placeholder='Nombre de pièces principales'/>
              </div>
              <div>
                <h3 className='text-md font-semibold py-6'>Surface en m²</h3>
                <TextInput value={getFormDataValue('surface')} onValueChange={(e) => setFormDataValue('surface', e)} placeholder='Surface totale'/>
              </div>

              {
                getFormDataValue('typeHabitation') == 'APPARTEMENT'&&
                <div>
                  <h3 className='text-md font-semibold py-6'>Cet appartement est situé</h3>
                  <div className="grid grid-cols-3 mobile:grid-cols-1 gap-3">
                    <RadioSelect value={getFormDataValue('etageAppart')} onclick={() => setFormDataValue('etageAppart', 'RDC')} name='RDC'label='Rez-de-chaussée'/>
                    <RadioSelect value={getFormDataValue('etageAppart')} onclick={() => setFormDataValue('etageAppart', 'INTERMEDIAIRE')} name='INTERMEDIAIRE'label='Etage intermédiaire'/>
                    <RadioSelect value={getFormDataValue('etageAppart')} onclick={() => setFormDataValue('etageAppart', 'DERNIER')} name='DERNIER'label='Au derrnier étage'/>
                  </div>
                </div>
              }

              <h3 className='text-md font-semibold py-6'>Usage du logement</h3>
              <div className='grid grid-cols-3 mobile:grid-cols-1 gap-3 items-center justify-center'>
                <RadioSelect value={getFormDataValue('typeResidence')} onclick={() => setFormDataValue('typeResidence', 'RESIDENCE_PRINCIPALE')} name='RESIDENCE_PRINCIPALE'label='Résidence principale'/>
                <RadioSelect value={getFormDataValue('typeResidence')} onclick={() => setFormDataValue('typeResidence', 'RESIDENCE_SECONDAIRE')} name='RESIDENCE_SECONDAIRE'label='Résidence secondaire'/>
                <RadioSelect value={getFormDataValue('typeResidence')} onclick={() => setFormDataValue('typeResidence', 'RESIDENCE_MISE_EN_LOCATION')} name='RESIDENCE_MISE_EN_LOCATION'label='Résidence mise en location'/>
              </div>


              <button onClick={() => nextStep()} className='my-4 py-4 w-full bg-orange-500 rounded-md text-white font-semibold'>Etape suivante</button>
            </div>
          }

          { step == 3 &&
            <div>
              <h2 className='text-xl mobile:text-lg font-semibold text-center py-12 mobile:py-6'>Equipements du logement</h2>
              <p className='text-center text-lg py-4'>Pour estimer au mieux votre devis, nous avons besoin de quelques informations supplémentaires</p>
              
              <div className='flex justify-center'>
                <div className="h-3 bg-white w-1/2 rounded-xl relative">
                  <div className="h-3 w-3/5 bg-orange-500 rounded-xl absolute"></div>
                </div>
              </div>

              <h3 className='text-md font-semibold py-6'>Le logement est -il équipé de ces éléments ?</h3>
              <div className='grid grid-cols-3 mobile:grid-cols-1 gap-3 items-center justify-center'>
                <RadioSelect value={getFormDataValue('insertOuCheminee')} image={window.location.origin + '/images/chemine.svg'}  onclick={() => getFormDataValue('insertOuCheminee') == 'OUI' ? setFormDataValue('insertOuCheminee', 'NON') : setFormDataValue('insertOuCheminee', 'OUI')} name='OUI' label='Cheminé ou poêle à bois'/>
                <RadioSelect value={getFormDataValue('presenceVeranda')} image={window.location.origin + '/images/veranda.svg'}  onclick={() => getFormDataValue('presenceVeranda') == 'OUI' ? setFormDataValue('presenceVeranda', 'NON') : setFormDataValue('presenceVeranda', 'OUI')} name='OUI' label='Véranda'/>
                <RadioSelect value={getFormDataValue('dependancePlus30m')} image={window.location.origin + '/images/dependances.svg'} onclick={() => getFormDataValue('dependancePlus30m') == 'OUI' ? setFormDataValue('dependancePlus30m', 'NON') : setFormDataValue('dependancePlus30m', 'OUI')} name='OUI' label='Dépendance supérieur à 30m²'/>
                <RadioSelect value={getFormDataValue('moyenProtectionVols')} image={window.location.origin + '/images/alarme.svg'} onclick={() => getFormDataValue('moyenProtectionVols') == 'OUI' ? setFormDataValue('moyenProtectionVols', 'NON') : setFormDataValue('moyenProtectionVols', 'OUI')} name='OUI' label='Alarme antivol'/>
                <RadioSelect value={getFormDataValue('pieceSuperieura30m')} image={window.location.origin + '/images/piece.svg'} onclick={() => getFormDataValue('pieceSuperieura30m') == 'OUI' ? setFormDataValue('pieceSuperieura30m', 'NON') : setFormDataValue('pieceSuperieura30m', 'OUI')} name='OUI' label='Pièce supérieur à 30m²'/>
                <RadioSelect value={getFormDataValue('piscine')} image={window.location.origin + '/images/swimming-svgrepo-com.svg'} onclick={() => getFormDataValue('piscine') == 'OUI' ? setFormDataValue('piscine', 'NON') : setFormDataValue('piscine', 'OUI')} name='OUI' label='Piscine'/>
              </div>

              <h3 className='text-md font-semibold py-6'>Capital mobilier à assurer</h3>
              <select onChange={(e) => setFormDataValue('capitalMobilier', e.target.value)} className='py-3 w-full px-6 rounded-xl focus:outline-orange-500'>
                <option value="8000">Moins de 8000 €</option>
                <option value="12000">8000 à 16000 €</option>
                <option value="16000">16000 à 20000 €</option>
                <option value="20000">20000 à 24000 €</option>
                <option value="24000">24000 à 28000 €</option>
                <option value="32000">28000 à 32000 €</option>
              </select>

              {
                getFormDataValue('pieceSuperieura30m') == 'OUI' && 
                <>
                  <h3 className='text-md font-semibold py-6'>Nombre de pièces supérieur à 30m²</h3>
                  <TextInput value={getFormDataValue('nbrPiecePrincipalePlus30m')} onValueChange={(e) => setFormDataValue('nbrPiecePrincipalePlus30m', e)} placeholder='Nombre de pièces supérieur à 30m²'/>
                </>
              }
              {
                getFormDataValue('dependancePlus30m') == 'OUI' &&
                <>
                  <h3 className='text-md font-semibold py-6'>Nombre de dépendances de plus de 30 m²</h3>
                  <TextInput value={getFormDataValue('nbrDependancePlus30m')} onValueChange={(e) => setFormDataValue('nbrDependancePlus30m', e)} placeholder='Nombre de pièces supérieur à 30m²'/>
                </>
              }
              {
                getFormDataValue('dependancePlus30m') == 'OUI' &&
                <>
                  <h3 className='text-md font-semibold py-6'>Nombre de dépendances</h3>
                  <TextInput value={getFormDataValue('nbrDependance')} onValueChange={(e) => setFormDataValue('nbrDependance', e)} placeholder='Nombre de dépendances'  />
                </>
              }
              <button onClick={() => nextStep()} className='my-4 py-4 w-full bg-orange-500 rounded-md text-white font-semibold'>Etape suivante</button>
            </div>
          }

          {
            step == 4 &&
            <>
              <h2 className='text-xl mobile:text-lg font-semibold text-center py-12 mobile:py-6'>Equipements du logement</h2>
              <p className='text-center text-lg py-4'>Pour estimer au mieux votre devis, nous avons besoin de quelques informations supplémentaires</p>
              
              <div className='flex justify-center'>
                <div className="h-3 bg-white w-1/2 rounded-xl relative">
                  <div className="h-3 w-4/5 bg-orange-500 rounded-xl absolute"></div>
                </div>
              </div>

              <h3 className='text-md font-semibold py-6'>Avez-vous été résilié(e) par un assureur ?</h3>
              <div className='grid grid-cols-2 mobile:grid-cols-2 gap-3 items-center justify-center'>
                <RadioSelect onclick={(e) => setFormDataValue('resilieAutreAssureur', 'OUI')} name={'OUI'} value={getFormDataValue('resilieAutreAssureur')} label='Oui'/>
                <RadioSelect onclick={(e) => setFormDataValue('resilieAutreAssureur', 'NON')} name={'NON'} value={getFormDataValue('resilieAutreAssureur')} label='Non'/>
              </div>

              <h3 className='text-md font-semibold py-6'>Avez vous eu un sinistre ces deux dernières années ?</h3>
              <div className='grid grid-cols-2 mobile:grid-cols-2 gap-3 items-center justify-center'>
                <RadioSelect onclick={(e) => setFormDataValue('sinistres2ansDerniers', 'OUI')} name={'OUI'} value={getFormDataValue('sinistres2ansDerniers')} label='Oui'/>
                <RadioSelect onclick={(e) => setFormDataValue('sinistres2ansDerniers', 'NON')} name={'NON'} value={getFormDataValue('sinistres2ansDerniers')} label='Non'/>
              </div>

              <h3 className='text-md font-semibold py-6'>Le logement sera utilisé pour une activité professionnelle ?</h3>
              <div className='grid grid-cols-2 mobile:grid-cols-2 gap-3 items-center justify-center'>
                <RadioSelect value={getFormDataValue('habitationUsageProfessionel')} onclick={() => setFormDataValue('habitationUsageProfessionel', 'OUI')} name='OUI'label='OUI'/>
                <RadioSelect value={getFormDataValue('habitationUsageProfessionel')} onclick={() => setFormDataValue('habitationUsageProfessionel', 'NON')} name='NON'label='NON'/>
              </div>

              <button onClick={() => getDevis()} className='inline-flex items-center justify-center my-4 py-4 w-full bg-orange-500 rounded-md text-white font-semibold'>
                  {
                    loading && (
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                    )
                  }
                  {
                    loading ? 'En cours de chargement' : 'Obtenir mon devis'
                  }
              </button>
            </>
          }



          {
            step == 5 &&
            <div>
              <h2 className='text-xl mobile:text-lg font-semibold text-center py-12 mobile:py-6'>Vos formules</h2>
              <p className='text-center text-lg py-4'>Choisissez la formule adapté à vos besoins</p>

              <div className='flex justify-center'>
                <div className="h-3 bg-white w-1/2 rounded-xl relative">
                  <div className="h-3 w-2/3 bg-orange-500 rounded-xl absolute"></div>
                </div>
              </div>

                {formules && formules.length == 4 &&
                  <div className='grid grid-cols-4 mobile:grid-cols-1 gap-3 py-6'>
                  {formules.map((formule, index) => (
                  <div className={`p-4 border rounded-md bg-white shadow-md ${formulesSelected && formulesSelected.formule == formule.formule ? 'border-4 border-orange-500' : ''}`}>
                      <h2 className='font-semibold text-center text-2xl py-4'>{formule.formule}</h2>
                      

                      <p className='text-center pb-12'><span className='text-2xl font-semibold'>{toPrice(formule.tarif)}</span> / mois</p>


                      {
                        formule.formule == 'ECO'  &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li class="uncheck">Dommages électriques <strong></strong></li> 

                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>

                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                      {
                        formule.formule == 'ESSENTIELLE' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li class="uncheck">Dommages électriques <strong></strong></li> 

                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>

                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                      {
                        formule.formule == 'CONFORT'   &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li class="uncheck">Dommages électriques <strong></strong></li> 

                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>

                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                    
                      {
                        formule.formule == 'OPTIMALE' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li>Dommages électriques <strong></strong></li> 
                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>
                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                      {
                        formule.formule == 'COMPLETE' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li>Dommages électriques <strong></strong></li> 
                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>
                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                      {
                        formule.formule == 'PREMIUM' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li>Dommages électriques <strong></strong></li> 
                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>
                          <li>Objet de valeur à <strong>1 500 € / pièce </strong></li>
                        </ul>
                      }
                      {
                        formule.formule =='OPTIMUM' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li>Dommages électriques <strong></strong></li> 
                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>
                          <li>Objet de valeur à <strong>1 500 € / pièce </strong></li>
                        </ul>
                      }

                      <div className='flex justify-center'>
                        <button onClick={() => setFormulesSelected(formule)} className='bg-orange-500 text-white px-4 py-2 rounded-lg mt-4'>Choisir</button>
                      </div>

                      
                  </div>
                  ))}
                </div>
                }
                {formules && formules.length == 3 &&
                  <div className='grid grid-cols-3 mobile:grid-cols-1 gap-3 py-6'>
                  {formules.map((formule, index) => (
                  <div className={`p-4 border rounded-md bg-white shadow-md ${formulesSelected && formulesSelected.formule == formule.formule ? 'border-4 border-orange-500' : ''}`}>
                      <h2 className='font-semibold text-center text-2xl py-4'>{formule.formule}</h2>
                      

                      <p className='text-center pb-12'><span className='text-2xl font-semibold'>{toPrice(formule.tarif)}</span> / mois</p>


                      {
                        formule.formule == 'ECO'  &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li class="uncheck">Dommages électriques <strong></strong></li> 

                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>

                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                      {
                        formule.formule == 'ESSENTIELLE' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li class="uncheck">Dommages électriques <strong></strong></li> 

                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>

                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                      {
                        formule.formule == 'CONFORT'   &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li class="uncheck">Dommages électriques <strong></strong></li> 

                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>

                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                    
                      {
                        formule.formule == 'OPTIMALE' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li>Dommages électriques <strong></strong></li> 
                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>
                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                      {
                        formule.formule == 'COMPLETE' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li>Dommages électriques <strong></strong></li> 
                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>
                          <li>Objet de valeur à <strong>1 000 € / pièce</strong></li>                                        
                        </ul>
                      }
                      {
                        formule.formule == 'PREMIUM' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li>Dommages électriques <strong></strong></li> 
                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>
                          <li>Objet de valeur à <strong>1 500 € / pièce </strong></li>
                        </ul>
                      }
                      {
                        formule.formule =='OPTIMUM' &&
                        <ul className='text-xs'> 
                          <li>Responsabilité civile <strong></strong></li>
                          <li>Défense Pénale et Recours <strong></strong></li>
                          <li>Incendie, risques annexes <strong></strong></li>
                          <li>Dégâts des eaux <strong></strong></li>
                          <li>Evènements climatiques <strong></strong></li>
                          <li>Bris de glace <strong></strong></li>
                          <li>Vol et vandalisme <strong></strong></li>
                          <li>Dommages électriques <strong></strong></li> 
                          <li>Capital mobilier Jusqu'à <strong>10 000 € / pièce</strong></li>
                          <li>Objet de valeur à <strong>1 500 € / pièce </strong></li>
                        </ul>
                      }

                      <div className='flex justify-center'>
                        <button onClick={() => setFormulesSelected(formule)} className='bg-orange-500 text-white px-4 py-2 rounded-lg mt-4'>Choisir</button>
                      </div>
                  </div>
                  ))}
                </div>
                }
              
              <button onClick={() => nextStep()} className='my-4 py-4 w-full bg-orange-500 rounded-md text-white font-semibold'>Valider mon choix</button>
              <div class="grid grid-cols-3">
                <div className='flex items-center justify-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                  </svg>
                  <a target='_blank' href={window.location.origin + '/images/IPID MRH-Generali-072020.pdf'}>Document d'information IPID</a>
                </div>
                <div className='flex items-center justify-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                  </svg>
                  <a target='_blank' href={window.location.origin + '/images/tableau_garantie.pdf'}>Voir le tableau de garantie</a>
                </div>
                <div className='flex items-center justify-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                  </svg>
                  <a target='_blank' href={window.location.origin + '/images/conditions_generales.pdf'}>Conditions Générales</a>
                </div>
              </div>
            </div>
          }

          {
            step == 6 &&
            <div>
              <h2 className='text-xl mobile:text-lg font-semibold text-center py-12 mobile:py-6'>Souscrire à votre assurance</h2>
              <p className='text-center text-lg py-4'>Afin de finaliser votre souscription, nous allons générer votre contrat</p>

              <div className='flex justify-center'>
                <div className="h-3 bg-white w-1/2 rounded-xl relative">
                  <div className="h-3 w-2/3 bg-orange-500 rounded-xl absolute"></div>
                </div>
              </div>


              <div className="grid grid-cols-3 gap-4 mobile:grid-cols-1 mobile:gap-2">
              
                <div className="">
                  <h3 className='text-md font-semibold py-6'>Adresse postale</h3>
                  <TextInput value={getFormDataValue('souscripteurAdressePostale')} onValueChange={(e) => setFormDataValue('souscripteurAdressePostale', e)}  />
                </div>
                <div className="">
                  <h3 className='text-md font-semibold py-6'>Ville</h3>
                  <TextInput value={getFormDataValue('souscripteurVille')} onValueChange={(e) => setFormDataValue('souscripteurVille', e)} />
                </div>
                <div className="">
                  <h3 className='text-md font-semibold py-6'>Code postal</h3>
                  <TextInput formatter={postcodeFormatter} value={getFormDataValue('souscripteurCodePostal')} onValueChange={(e) => setFormDataValue('souscripteurCodePostal', e)}  />
                </div>
                <div className="">
                  <h3 className='text-md font-semibold py-6'>Civilité</h3>
                  <select  onChange={(e) => setFormDataValue('souscripteurCV', e.target.value)} className='py-3 w-full px-6 rounded-xl focus:outline-orange-500'>
                    <option value="MR">Monsieur</option>
                    <option value="MME">Madame</option>
                  </select>
                </div>
                
                <div className="">
                  <h3 className='text-md font-semibold py-6'>Nom</h3>
                  <TextInput value={getFormDataValue('souscripteurNom')} onValueChange={(e) => setFormDataValue('souscripteurNom', e)}  />
                </div>
                <div className="">
                  <h3 className='text-md font-semibold py-6'>Prénom</h3>
                  <TextInput value={getFormDataValue('souscripteurPrenom')} onValueChange={(e) => setFormDataValue('souscripteurPrenom', e)}  />
                </div>
                <div>
                  <h3 className='text-md font-semibold py-6'>Email</h3>
                  <TextInput value={getFormDataValue('souscripteurEmail')} onValueChange={(e) => setFormDataValue('souscripteurEmail', e)}  />
                </div>
                <div>
                  <h3 className='text-md font-semibold py-6'>Numéro de téléphone</h3>
                  <TextInput formatter={formatFrenchPhoneNumber} value={getFormDataValue('souscripteurTel')} onValueChange={(e) => setFormDataValue('souscripteurTel', e)}  />
                </div>
                
               
                <div className="">
                  <h3 className='text-md font-semibold py-6'>Votre situation familiale</h3>
                  <select onChange={(e) => setFormDataValue('souscripteursituationFam', e.target.value)} className='py-3 w-full px-6 rounded-xl focus:outline-orange-500'>
                    <option value="CELIBATAIRE">Célibataire</option>
                    <option value="MARIE">Marié</option>
                    <option value="PACSE">Pacsé</option>
                    <option value="CONCUBIN">Concubinage</option>
                    <option value="DIVORCE">Divorcé</option>
                    <option value="SEPARE">Séparé</option>
                    <option value="VEUF">Veuf(ve)</option>
                  </select>
                </div>
                
              </div>
              <div className="">
                <h3 className='text-md font-semibold py-6'>Votre date de naissance</h3>
                  <TextInput placeholder={'JJ/MM/AAAA'} formatter={dateWithSlashFormatter} value={getFormDataValue('dateNaissance')} onValueChange={(e) => setFormDataValue('dateNaissance', e)}  />
                </div>
              <div>
                <h3 className='text-md font-semibold py-6'>Votre IBAN pour les prélèvements</h3>
                <TextInput formatter={ibanFormatter} value={getFormDataValue('souscripteuribanPrelevemnt')} onValueChange={(e) => setFormDataValue('souscripteuribanPrelevemnt', e)}  />
              </div>

              <div className='py-12'>
                <button onClick={() => getContrat()} className='inline-flex items-center justify-center my-4 py-4 w-full bg-orange-500 rounded-md text-white font-semibold'>
                {
                    loading && (
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    )
                  }
                  {
                    loading ? 'Création de votre devis' : 'Signer mon contrat en ligne'
                  }
                  
                </button>
              </div>
             
             

            </div>
          }
          {
            step == 7 &&
            <div className='w-full'>
              <div className="w-full">
                <embed width={'100%'} height={'500px'} src={`data:application/pdf;base64,${pdf}`} />
              </div>
              <p className='text-xs text-center'>Vous allez être redirigé vers une page pour souscrire à votre contrat</p>
              <div className='py-12'>
                <button onClick={() => getContrat('subscription')} className='inline-flex items-center justify-center  my-4 py-4 w-full bg-orange-500 rounded-md text-white font-semibold'>
                {
                    loading && (
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    )
                  }
                  {
                    loading ? 'Enregistrement de votre contrat' : 'Payer par carte bancaire'
                  }
                </button>
              </div>
            </div>
          }

          <div className='border-y-2 border-orange py-6 my-4'>
            <p class="text-lg font-bold flex items-center">
            <svg className='mr-12' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
            </svg>

              <span className='ml-4'>Bon à savoir</span></p>
              <p className='py-6'>Déjà assuré(e) ailleurs ? Assurmabarak peut s’occuper de résilier votre ancien contrat gratuitement.</p>
          </div>



        </div>
      </div>
    </div>
  )
}

export default GenerateDevis